import UserActionTypes from './user.types';
import { CredentialType, UserType , ErrorType } from '../../modules/commonTypes'


export const signIn = (userCredentials: CredentialType) => ({
    type: UserActionTypes.SIGN_IN_START,
    payload: userCredentials
});


export const signInSuccess = (userInfo: UserType) => ({
    type: UserActionTypes.SIGN_IN_SUCCESS,
    payload: userInfo
});

export const signInFailure = (error : ErrorType) => ({
    type: UserActionTypes.SIGN_IN_FAILURE,
    payload: error
});

export const checkUserSession = () => ({
    type: UserActionTypes.CHECK_USER_SESSION
});

export const signOut = () =>({
    type: UserActionTypes.SIGN_OUT_START
});

export const signOutSuccess = () =>({
    type: UserActionTypes.SIGN_OUT_SUCCESS
});

export const signOutFailure = (error : ErrorType) =>({
    type: UserActionTypes.SIGN_OUT_FAILURE,
    payload: error
});
