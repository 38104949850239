import { useEffect } from 'react';
import queryString from 'query-string';
import { Dispatch } from 'redux';
import { MetadataQueryType, ErrorType } from '../../modules/commonTypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchToken } from '../../redux/token/token.actions';
import { fetchMetadata } from '../../redux/metadata/metadata.actions';
import { selectIsTokenLoaded, selectIsTokenLoading, selectTokenError } from '../../redux/token/token.selectors';
import { selectIsMetadataLoading, selectIsMetadataLoaded, selectMetadataError } from '../../redux/metadata/metadata.selectors';
import LoaderPage from '../loader-page/loader-page.component';
import MetadataMenu  from '../../components/metadata-menu/metadata-menu.component';
import MetadataPage from './metadata-page.component';
import { withRouter } from 'react-router-dom';

type Props = {
    isTokenLoading: boolean;
    isTokenLoaded: boolean;
    tokenError: ErrorType;
    isMetadataLoading: boolean;
    isMetadataLoaded: boolean;
    metadataError: ErrorType;
    fetchToken: (tokenId: string) => void;
    fetchMetadata: (query: MetadataQueryType) => void;
    match: any;
    location: any;
}

const MetadataPageContainer = ({ isTokenLoading, isTokenLoaded, tokenError, isMetadataLoading, isMetadataLoaded, metadataError, fetchToken, fetchMetadata, match, location } : Props) => {
    
    let tokenId = match.params.tokenId;

    useEffect(()=> {
        fetchToken(tokenId);
    }, [fetchToken, tokenId]);

    useEffect(()=> {
        let query = queryString.parse(location.search);
        fetchMetadata({tokenId, ...query});
    }, [fetchMetadata, tokenId, location.search]);

    const retryFuncs = [
        () => fetchToken(tokenId),
        () => fetchMetadata({tokenId, ...queryString.parse(location.search)})
    ]
    return (
        <>
            <MetadataMenu />
            {
                (isMetadataLoaded && isTokenLoaded && !tokenError && !metadataError) ? 
                <MetadataPage />
                :
                <LoaderPage 
                    isLoading={isTokenLoading || isMetadataLoading}
                    error={ tokenError || metadataError}
                    retryFuncs={retryFuncs}/>
            }
        </>
    )
};

const mapStateToProps = createStructuredSelector({
    isTokenLoading: selectIsTokenLoading,
    isTokenLoaded: selectIsTokenLoaded,
    tokenError: selectTokenError,
    isMetadataLoading: selectIsMetadataLoading,
    isMetadataLoaded: selectIsMetadataLoaded,
    metadataError: selectMetadataError
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchToken: (tokenId : string) => dispatch(fetchToken(tokenId)),
    fetchMetadata: (query: MetadataQueryType) => dispatch(fetchMetadata(query)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MetadataPageContainer));
