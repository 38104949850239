
import './footer.styles.scss';

const Footer = () => {
    return (
    <div className="page-footer">
        <div className="column copyright">
            <div className="note">
                Mobius Labs GmbH. All Rights Reserved.
            </div>
            <div className="note">&copy; 2023</div>
        </div>
    </div>
)};

export default Footer;
