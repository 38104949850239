import { createSelector } from 'reselect';


const selectMetadataState = (state: any) => state.metadata;

export const selectMetadata = createSelector(
    [selectMetadataState],
    metadata => metadata.metadata
);

export const selectIsMetadataLoading = createSelector(
    [selectMetadataState],
    metadata => metadata.loading
);

export const selectIsMetadataLoaded = createSelector(
    [selectMetadataState],
    metadata => !!metadata.metadata
);

export const selectMetadataQuery = createSelector(
    [selectMetadataState],
    metadata => metadata.query
);

export const selectMetadataError = createSelector(
    [selectMetadataState],
    metadata => metadata.error
);

export const selectMetadataStatus = createSelector(
    [selectMetadataState],
    metadata => metadata.status
);
