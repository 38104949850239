import CompanyActionTypes from './company.types';
import { CompanyType, ErrorType } from '../../modules/commonTypes'

// Fetch
export const fetchCompanies = () => ({
    type: CompanyActionTypes.FETCH_COMPANIES_START
});

export const fetchCompaniesSuccess = (Companies: CompanyType[]) => ({
    type: CompanyActionTypes.FETCH_COMPANIES_SUCCESS,
    payload: Companies
});

export const fetchCompaniesFailure = (error: ErrorType) => ({
    type: CompanyActionTypes.FETCH_COMPANIES_FAILURE,
    payload: error
});
