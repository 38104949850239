import { useEffect} from 'react';
import { Dispatch } from 'redux';

import CompaniesPage from './companies-page.component';
import LoaderPage from '../loader-page/loader-page.component';
import { ErrorType } from '../../modules/commonTypes';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchCompanies } from '../../redux/company/company.actions';
import { selectCompanyError, selectIsCompaniesLoaded, selectIsCompaniesLoading } from '../../redux/company/company.selectors';

import './companies-page.styles.scss';

type Props = {
    isLoading: boolean;
    isLoaded: boolean;
    error: ErrorType;
    fetchCompanies: () => void;
}

const CompaniesPageContainer = ({ isLoaded, isLoading, error, fetchCompanies}: Props) => {

    useEffect(()=> {
        fetchCompanies();
    }, [fetchCompanies]);

    return (isLoaded && !error) ?
        <CompaniesPage />
        :
        <LoaderPage isLoading={isLoading} error={error} retryFuncs={[fetchCompanies]}/>
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsCompaniesLoading,
    isLoaded: selectIsCompaniesLoaded,
    error: selectCompanyError,
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchCompanies: () => dispatch(fetchCompanies()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesPageContainer);
