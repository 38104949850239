import './spinner.styles.scss';


const Spinner = () => (
    <div className="spinner-overlay">
        <div className="spinner-container" />
    </div>
);

export default Spinner;
