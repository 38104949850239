import { takeLatest, call, put, all } from 'redux-saga/effects';

import { getCompanies } from '../../modules/apiHelper';
import CompanyActionTypes from './company.types';

import { 
    fetchCompaniesSuccess,
    fetchCompaniesFailure,
    } from './company.actions';

export function* fetchCompaniesAsync():any {
    try {
        const response = yield getCompanies()
        const { companies } = response.data;
        yield put(fetchCompaniesSuccess(companies));
    } catch(error : any) {
        yield put(fetchCompaniesFailure(error.data));
    }
}


export function* onFetchCompanies() {
    yield takeLatest(
        CompanyActionTypes.FETCH_COMPANIES_START,
        fetchCompaniesAsync
    );
}


export function* companySagas(){
    yield all([
        call(onFetchCompanies),
    ]);
}
