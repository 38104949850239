import { takeLatest, put, all, call } from 'redux-saga/effects';
import UserActionTypes from './user.types';
import Auth from '../../modules/authenticate';
import { signIn } from '../../modules/apiHelper';

import { AnyAction } from '../../modules/commonTypes'

import {
        signInSuccess,
        signInFailure,
        signOutSuccess,
        signOutFailure,
    } from './user.actions';


export function* signInAsync({payload: {username, password, remember}} : AnyAction):any {
    try {
        const response : any = yield signIn(username, password, remember);

        const { access_token, token_type, user } = response.data;

        yield put(signInSuccess(user));
        user.access_token = access_token
        user.token_type = token_type
        yield Auth.setUser(user);

    } catch(error: any) {
        yield put(signInFailure(error.data));
    }
}

export function* isUserAuthenticated():any {
    try {
        const user: any = yield Auth.getUser();
        if(user) {
            yield put(signInSuccess(user));
        } else {

        }

    } catch (error: any) {
        yield put(signInFailure(error.data));
    }
}

export function* signOutAsync(){
    try {
        yield Auth.signOut();
        yield put(signOutSuccess());
    } catch(error: any) {
        yield put(signOutFailure(error.data));
    }
}


/////////////////////////////////////////////////////////////////////////////////////////
export function* onSignIn() {
    yield takeLatest(
        UserActionTypes.SIGN_IN_START,
        signInAsync
    );
}

export function* onCheckUserSession() {
    yield takeLatest(
        UserActionTypes.CHECK_USER_SESSION,
        isUserAuthenticated
    )
}

export function* onSignOut() {
    yield takeLatest(
        UserActionTypes.SIGN_OUT_START,
        signOutAsync
    );
}

export function* userSagas(){
    yield all([
        call(onSignIn),
        call(onCheckUserSession),
        call(onSignOut),
    ]);
}
