import Moment from 'react-moment';
import moment from 'moment';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { UserType, TokenType } from '../../modules/commonTypes';

import { FaKey, FaEye, FaEyeSlash } from 'react-icons/fa';
import Badge from '../badge/badge.component';
import CustomButton from '../custom-button/custom-button.component';
import { Link } from 'react-router-dom';
import './token.styles.scss';

type Props = {
    currentUser: UserType;
    item: TokenType;
}

const Token = ({ currentUser, item }: Props) => {
    const {name, token, expired, visibility} = item;
    let expiredFlag = expired * 1000 < moment.now()

    return (
        <div className={`token-item ${expiredFlag?"expired":null}`}>
            <div className='image'>
                <div className='icon'>
                    <FaKey size={80}/>
                </div>
            </div>
            { Boolean(expiredFlag) && <Badge content={"Expired"} /> }
            <div className='visibility-icon'>
                { 
                    currentUser.role === 'admin' ? visibility ?
                    <FaEye size={24}/>
                    :
                    <FaEyeSlash size={24}/>
                    :
                    null
                }
            </div>
            <div className='info'>
                <div className='row token-name'>
                    <div className='row-block key'><span>Token Name</span></div>
                    <div className='row-block value name'><span>{name}</span></div>
                </div>
                <div className='row token-key'>
                    <div className='row-block key'><span>Token Key</span></div>
                    <div className='row-block value'><span>{token}</span></div>
                </div>
                
                <div className='row expiration'>
                    <div className='row-block key'><span>Expiration</span></div>
                    <div className='row-block value'>
                        <span><Moment date={expired * 1000} format="YYYY/MM/DD"/></span>
                    </div>
                </div>
                <div className='row expiration'>
                    <div className='row-block key'><span></span></div>
                    <div className='row-block value'>
                        <span>{moment(expired * 1000).startOf('day').fromNow()}</span>
                    </div>
                </div>
            </div>
            <Link className="view-link" to={`/dashboard/tokens/${token}/usages`}>
                <CustomButton className="custom-button" inverted>
                    Usage
                </CustomButton>
            </Link>

            {
                Boolean(currentUser.role === "admin") &&
                <Link className="view-link" to={`/dashboard/tokens/${token}/metadata`}>
                    <CustomButton className="custom-button" inverted>
                        Metadata
                    </CustomButton>
                </Link>
            }
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

export default connect(mapStateToProps)(Token);
