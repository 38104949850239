import { all, call} from 'redux-saga/effects';

import { userSagas }     from './user/user.sagas';
import { companySagas }  from './company/company.sagas';
import { tokenSagas }    from './token/token.sagas';
import { usageSagas }    from './usage/usage.sagas';
import { metadataSagas } from './metadata/metadata.sagas';

export default function* rootSaga() {
    yield all([
        call(userSagas),
        call(companySagas),
        call(tokenSagas),
        call(usageSagas),
        call(metadataSagas),
    ]);
}
