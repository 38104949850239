import React, { useEffect } from 'react';
import { setQuery } from '../../modules/navigation';

import { connect } from 'react-redux';

import { createStructuredSelector } from 'reselect';
import { selectSingleToken } from '../../redux/token/token.selectors';
import { selectMetadataQuery } from '../../redux/metadata/metadata.selectors';
import { MetadataQueryType } from '../../modules/commonTypes';

import DateRangePicker from '../date-range-picker/date-range-picker.component';

import './metadata-menu.styles.scss';

type Props = {
    query: MetadataQueryType;
}

const MetadataMenu = ({ query }: Props) => {

    let lastScrollY = 0;
    let ticking = false;

    const navMenu = React.useRef<any>(null);

    const handleDateRange = (data : any) => {
        let {start_date, end_date } = data;
        setQuery({...query, start_date, end_date});
    }

    let handleScroll = () => {
        lastScrollY = window.scrollY;
        if (!ticking) {
            navMenu.current && window.requestAnimationFrame(() => {
                navMenu.current.style.height = lastScrollY > 15 ? `0`: `3rem`;
                navMenu.current.style.opacity = lastScrollY > 15 ? `0`: `1`;
                navMenu.current.style.pointerEvents = lastScrollY > 15 ? `none`: `auto`;

                ticking = false;
            });
            ticking = true;
        }
    };
    
    useEffect(()=> {
        window.addEventListener('scroll', handleScroll, true);
    });

    return (
    <div className="metadata-menu" >
        <div className="navbar" ref={navMenu}>
            <DateRangePicker start_date={query?.start_date} end_date={query?.end_date} onChange={handleDateRange}/>
        </div>
    </div>);
}	

const mapStateToProps = createStructuredSelector({
    token: selectSingleToken,
    query: selectMetadataQuery,
})

export default connect(mapStateToProps)(MetadataMenu);
