import { useMemo } from 'react';
import moment from 'moment';
import { deepCopy, nameFormatter } from '../../modules/common';
import { FaLaptop } from 'react-icons/fa';
import TimeTable from '../time-table/time-table.component';

import { MetadataType } from '../../modules/commonTypes';

import './metadata.styles.scss';

type Props = {
    query?: any;
    metadata: {
        mac_address: string;
        metadata: MetadataType[];
    };
};

const Metadata = ({ query ={}, metadata } : Props) => {
    const { mac_address, metadata: metadata_list } = metadata;

    let build_info = useMemo(() => {
        const view_keys = ['version', 'commit_hash', 'build_type', 'models'];
        const info = deepCopy(metadata_list[0]);
        info.models = info.models? info.models.join(', ') : '';

        return {view_keys, info}
    }, [metadata_list])
    
    
    let tableData = useMemo(()=> {
        
        // columns
        let columns = []
        const del_keys = ['id','mac_address', 'timestamp', ...build_info.view_keys];
        columns = Object.keys(build_info.info).filter(key => !del_keys.includes(key));

        let data = metadata_list.map(({ timestamp, ...row }) => ({timestamp, row}));

        // filter data
        let filterData = [...data];
        if (query.time_range) {
            let low = query.time_range.low
            let high = query.time_range.high
            filterData = data.filter(item => {
                let t = moment(item.timestamp).unix() * 1000;
                return (low <= t) && (t <= high);
            });
        }

        filterData.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));

        return {columns, data: filterData}
    }, [query.time_range, metadata_list, build_info]);

    return (
        <div className='metadata-item'>
            <div className="item-header">
                <span>
                    <FaLaptop size={24}/>
                </span>
                <span>
                    {mac_address.toUpperCase() || 'GENERAL'}
                </span>
            </div>
            <div className='info'>
                {
                    build_info.view_keys.map(key => {
                        return (
                            <div className='row token-name' key={key}>
                                <div className='row-block key'><span>{nameFormatter(key)}</span></div>
                                <div className='row-block value'><span>{build_info.info[key]}</span></div>
                            </div>
                        )
                    })
                }
                <TimeTable columns={tableData.columns} data={tableData.data} />
            </div>
        </div>
    )
}

export default Metadata;
