import UserActionTypes from './user.types';
import { AnyAction } from '../../modules/commonTypes'
import jumpTo from '../../modules/navigation';

const INITIAL_STATE = {
    currentUser: null,
    isLoading: false,
    status: null,
    error: null
}

const userReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case UserActionTypes.SIGN_IN_START:
        case UserActionTypes.SIGN_OUT_START:
            return {
                ...state,
                isLoading: true,
                status: action.type
            }
        case UserActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser : action.payload,
                isLoading: false,
                status: action.type,
                error: null
            }
        case UserActionTypes.SIGN_OUT_SUCCESS:
            jumpTo('/dashboard')
            return {
                ...state,
                currentUser : null,
                isLoading: false,
                status: action.type,
                error: null
            }
        case UserActionTypes.SIGN_IN_FAILURE:
        case UserActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                isLoading: false,
                status: action.type,
                error: action.payload
            }
        default:
            return state;
    }
}

export default userReducer;
