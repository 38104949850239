import MetadataActionTypes from './metadata.types';
import { MetadataType, MetadataQueryType, ErrorType } from '../../modules/commonTypes'

// Fetch
export const fetchMetadata = (query: MetadataQueryType) => ({
    type: MetadataActionTypes.FETCH_METADATA_START,
    payload: query
});

export const fetchMetadataSuccess = (query: MetadataQueryType, metadata: MetadataType[]) => ({
    type: MetadataActionTypes.FETCH_METADATA_SUCCESS,
    payload: {query, metadata}
});

export const fetchMetadataFailure = (error: ErrorType) => ({
    type: MetadataActionTypes.FETCH_METADATA_FAILURE,
    payload: error
});
