import SignIn from '../../components/sign-in/sign-in.component';

import './sign-page.styles.scss';


const SignPage = () => {
    return (
    <div className="sign-page">
        <SignIn />
    </div>
)};

export default SignPage;
