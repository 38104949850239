const enum UserActionTypes {
    CHECK_USER_SESSION = 'CHECK_USER_SESSION',

    SIGN_IN_START   = 'SIGN_IN_START',
    SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE = 'SIGN_IN_FAILURE',

    SIGN_OUT_START   = 'SIGN_OUT_START',
    SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS',
    SIGN_OUT_FAILURE = 'SIGN_OUT_FAILURE',
}

export default UserActionTypes;
