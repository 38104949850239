const enum TokenActionTypes {
    FETCH_TOKEN_START   = 'FETCH_TOKEN_START',
    FETCH_TOKEN_SUCCESS = 'FETCH_TOKEN_SUCCESS',
    FETCH_TOKEN_FAILURE = 'FETCH_TOKEN_FAILURE',

    UPDATE_TOKEN_START   = 'UPDATE_TOKEN_START',
    UPDATE_TOKEN_SUCCESS = 'UPDATE_TOKEN_SUCCESS',
    UPDATE_TOKEN_FAILURE = 'UPDATE_TOKEN_FAILURE',

    FETCH_TOKENS_START   = 'FETCH_TOKENS_START',
    FETCH_TOKENS_SUCCESS = 'FETCH_TOKENS_SUCCESS',
    FETCH_TOKENS_FAILURE = 'FETCH_TOKENS_FAILURE',
};

export default TokenActionTypes;
