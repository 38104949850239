import { nameFormatter } from '../../modules/common';
import Spinner from '../../components/spinner/spinner.component';
import CustomButton from '../../components/custom-button/custom-button.component';
import { ErrorType } from '../../modules/commonTypes';
import './loader-page.styles.scss';

type Props = {
    isLoading: boolean;
    error: ErrorType;
    retryFuncs: Function[];
}

const LoaderPage = ({isLoading, error, retryFuncs} : Props) => {
    const handleClick = (e: any) =>{
        retryFuncs.forEach(func => func());
    }

    return (      
    <div className="loader-page">
        {
            isLoading ? 
            <Spinner />
            :
            <div className='container'>
                <div className='error-message'>
                    {nameFormatter(error?.message || 'Error')}
                </div>
                <CustomButton inverted onClick={handleClick}>Retry</CustomButton>
            </div>
        }
    </div>
)};

export default LoaderPage;
