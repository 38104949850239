import { useMemo } from 'react';
import moment from 'moment';
import { FaLaptop } from 'react-icons/fa';
import TimeTable from '../time-table/time-table.component';
import { UsageType } from '../../modules/commonTypes';

import './usage.styles.scss';

type Props = {
    title?: string;
    query: any;
    mode?: string;
    item: UsageType
}

const Usage = ({ query, mode, item, title }: Props) => {
    const { mac_address, usages } = item;
    
    let tableData = useMemo(()=> {
        let model_list: string[] = Array.from(usages.reduce((acc : any, { models }) => {
            let model_names: any = new Set(models.map(model => model.name))
            return new Set([...acc, ...model_names])
        }, new Set()));
        model_list.sort();

        let data = usages.map(({ timestamp, models }) => {
            let usage_dict = models.reduce((acc : any, {name, used}) => {
                acc[name] = (acc[name] || 0) + used;
                return acc;
            }, {});
            return {timestamp, row: usage_dict}
        })

        // Filter Data
        let filterData = [...data];
        if (query.time_range) {
            let low = query.time_range.low
            let high = query.time_range.high
            filterData = data.filter(item => {
                let t = moment(item.timestamp).unix() * 1000;
                return (low <= t) && (t <= high);
            });
        }

        filterData.sort((a, b) => +new Date(b.timestamp) - +new Date(a.timestamp));
        let overall = filterData.reduce((acc: any, { row }) => {
            for (const [name, used] of Object.entries(row)) {
                acc[name] = acc[name] ? acc[name] + used : used;
            }
            return acc
        }, {});
        return { columns: model_list, data: filterData, overall};
    }, [query.time_range, usages]);

    return (
        <div className='usages'>
            <div className='item-header'>
                <span>
                    <FaLaptop size={24}/>
                </span>
                <span>
                    {title || 'MAC ADDRESS'}: {mac_address.toUpperCase() || 'GENERAL'}
                </span>
            </div>
            <div className='info'>
                <TimeTable mode={mode} columns={tableData.columns} data={tableData.data} overall={tableData.overall} />
            </div>
        </div>
    )
}

export default Usage;
