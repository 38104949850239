import moment from 'moment';
import { nameFormatter } from '../../modules/common';

import './time-table.styles.scss';

type HeaderProps = {
    columns: string[];
};

const TableHeader = ({columns = []} : HeaderProps) => (
    <div className="table-header noselect">
        <div className="header-block">
            <span>Time</span>
        </div>
        {
            columns.map((item, i) =>
                <div className="header-block" key={i}>
                    <span>{nameFormatter(item)}</span>
                </div>
            )
        }
    </div>
)

const render_time = (timestamp : string, mode : string) => {
    let time = moment(timestamp, 'YYYY-MM-DD')
    switch (mode.toLowerCase()) {
        case "monthly":
            return `${time.year()} ${moment().month(time.month()).format("MMM")}`;
        case "weekly":
            return `${time.year()} - Week #${time.week()}`;
        case "daily":
            return `${time.year()} ${moment().month(time.month()).format("MMM")} ${time.date()}`;
        default:
            return timestamp; 
    }
}

type ItemProps = {
    mode?: string;
    columns: string[];
    item: {
        timestamp: string;
        row: {[key: string]: number}
    }
};

const TableItem = ({mode="daily", columns = [], item} : ItemProps) => {
    const {timestamp, row} = item;

    return (
        <div className="table-item">
            <div className="row-block noselect timestamp">
                <span>{render_time(timestamp, mode)}</span>
            </div>
            {
                columns.map((name, i) => 
                    <div key={i} className="row-block noselect">
                        <span>{row[name] || 0}</span>
                    </div>
                )
            }
            
        </div>
    );
}

type FooterProps = {
    columns: string[];
    row: {[key: string]: number};
};

const TableFooter = ({columns = [], row = {}} : FooterProps) => (
    <div className="table-footer noselect">
        <div className="footer-block overall">
            <span>Overall</span>
        </div>
        {
            columns.map((item, i) =>
                <div className="footer-block" key={i}>
                    <span>{row[item] || 0}</span>
                </div>
            )
        }
    </div>
)

type TableProps = {
    mode?: string;
    columns: string[];
    data: any[];
    overall?: any;
};

export const TimeTable = ({ mode, columns=[], data=[], overall=null} : TableProps ) => {
    return (
        <div className='time-table'>
            <TableHeader columns={columns}/>
            {
                data.map((item, i) => <TableItem key={i} mode={mode} columns={columns} item={item}/>)
            }
            {
                Boolean(overall) && <TableFooter columns={columns} row={overall}/>
            }
        </div>
    )
}

export default TimeTable;
