import { connect } from 'react-redux';
import Logo  from '../../assets/images/mobius_logo_small.svg';
import { Dispatch } from 'redux';

import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';

import { UserType } from '../../modules/commonTypes';
import { signOut }  from '../../redux/user/user.actions';

import { FaSignOutAlt } from 'react-icons/fa';
import jumpTo from '../../modules/navigation';

import './header-top.styles.scss';

type Props = {
    currentUser: UserType;
    signOut: () => void;
};

const HeaderTop = ({currentUser, signOut} : Props) => {
    return (
    <div className="header-top">
        <div className="navbar">
            <div className="logo-container">
                <div className="image" onClick={()=> jumpTo("/dashboard")}>
                    <img src={Logo} alt="MobiusLabs"/>
                </div>
                <div className="text noselect">
                    <h2>Mobius Labs</h2>
                    <div className="desc" >Superhuman Vision</div>
                </div>
            </div>
            <div className="empty-space">

            </div>
            {
                currentUser &&
                <div onClick={signOut} className="nav-item exit">
                    <FaSignOutAlt size={24}/>
                </div>
            }
        </div>
    </div>
    );
}	

const mapStateToProps = createStructuredSelector({
    currentUser : selectCurrentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    signOut: () => dispatch(signOut())
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderTop);
