import Moment from 'react-moment';
import moment from 'moment';
import { FaLaptop, FaEye, FaEyeSlash } from 'react-icons/fa'
import Badge from '../badge/badge.component';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { updateToken } from '../../redux/token/token.actions';

import { UserType, TokenType } from '../../modules/commonTypes';

import './token-overview.styles.scss';

type Props = {
    currentUser: UserType;
    token_info: TokenType;
    devices_count: number;
    updateToken: (tokenId : string, data: any) => void;
};

const TokenOverview = ({ currentUser, token_info, devices_count, updateToken } : Props) => {
    const {name, token, time_created, expired, visibility, limits} = token_info;

    
    const handleVisibility = (e: any) => {
        updateToken(token, { visibility: !visibility})
    }
    
    let expiredFlag = expired * 1000 < moment.now();

    return (
        <div className='token-overview'>
            { Boolean(expiredFlag) && <Badge content={"Expired"} /> }
            <div className="item-header">
                Token Info
            </div>
            <div className='visibility-icon'>
                { 
                    currentUser?.role === 'admin' ? visibility ?
                    <FaEye size={24}/>
                    :
                    <FaEyeSlash size={24}/>
                    :
                    null
                }
            </div>
            <div className='info'>
                <div className='row token-name'>
                    <div className='row-block key'><span>Token Name</span></div>
                    <div className='row-block value'><span>{name}</span></div>
                </div>
                <div className='row token-key'>
                    <div className='row-block key'><span>Token Key</span></div>
                    <div className='row-block value'><span>{token}</span></div>
                </div>
                <div className='row token-key'>
                    <div className='row-block key'>
                        <span>Created Time</span>
                    </div>
                    <div className='row-block value'>
                        <span><Moment date={time_created} format="YYYY/MM/DD"/></span>
                    </div>
                </div>
                {
                    Boolean(currentUser?.role === 'admin') && 
                    <div className='row visibility-status'>
                        <div className='row-block key'><span>Visibility</span></div>
                        <div className='row-block value'>
                            <span className='status'>
                                { visibility ? "Visible" : "Hidden" }
                            </span>
                            <span className='button-container'>
                                <span className='button' onClick={handleVisibility}>Change</span>
                            </span>
                        </div>
                    </div>
                }
                {
                    Boolean(devices_count) &&
                    <div className='row devices'>
                    <div className='row-block key'>
                        <span className='icon'>
                            <FaLaptop size={24}/>
                        </span>
                    </div>
                    <div className='row-block value'><span>{`${devices_count} devices`}</span></div>
                </div>
                }
                <div className='row expiration'>
                    <div className='row-block key'><span>Expiration</span></div>
                    <div className='row-block value'>
                        <span><Moment date={expired * 1000} format="YYYY/MM/DD"/></span>
                    </div>
                </div>
                <div className='row expiration'>
                    <div className='row-block key'><span></span></div>
                    <div className='row-block value'>
                        <span>{moment(expired * 1000).startOf('day').fromNow()}</span>
                    </div>
                </div>
            </div>
            
            <div className='limit-title'>Overall Usages</div>
            <div className='limits-container'>
                {
                    (limits && limits.length) ? limits.map(item => {
                        let limitReached = item.limit && item.used > item.limit
                        return (
                            <div key={item.name} className={`limit-item ${limitReached?'limited':null}`}>
                                <div className='row'>
                                    <div className='row-block key'><span>Name</span></div>
                                    <div className='row-block value'><span>{item.name}</span></div>
                                </div>
                                <div className='row'>
                                    <div className='row-block key'><span>Limit</span></div>
                                    <div className='row-block value'><span>{item.limit || "Not Set"}</span></div>
                                </div>
                                <div className='row'>
                                    <div className='row-block key'><span>Used</span></div>
                                    <div className='row-block value'><span>{item.used}</span></div>
                                </div>
                            </div>
                        )
                    }):
                    <div className='empty-message'>No Record</div>
                }
            </div>
        </div>
    )
    
}

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = ( dispatch : Dispatch) => ({
    updateToken: (tokenId: string, data: any) => dispatch(updateToken(tokenId, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TokenOverview);
