import { takeLatest, call, put, all } from 'redux-saga/effects';

import { getUsages } from '../../modules/apiHelper';
import UsageActionTypes from './usage.types';
import { AnyAction } from '../../modules/commonTypes'

import { 
    fetchUsagesSuccess,
    fetchUsagesFailure,
    } from './usage.actions';

export function* fetchUsagesAsync({payload: data} : AnyAction):any {
    try {
        if (!data.mode){
            data.mode = "monthly"
        }
        const {tokenId, ...query} = data;
        const response = yield getUsages(tokenId, query);
        const { usages } = response.data;
        yield put(fetchUsagesSuccess(data, usages));
    } catch(error : any) {
        yield put(fetchUsagesFailure(error.data));
    }
}


export function* onFetchUsages() {
    yield takeLatest(
        UsageActionTypes.FETCH_USAGES_START,
        fetchUsagesAsync
    );
}


export function* usageSagas(){
    yield all([
        call(onFetchUsages),
    ]);
}
