import React from 'react';

import './form-input.styles.scss';

type Props = {
    name: string;
    value?: any;
    label?: string;
    required?: boolean;
    type?:string;
    placeholder?: string;
    handleChange: (e: any) => void;
}

const FormInput = ({handleChange, label, ...otherProps}: Props) => (
    <div className="group">
        {
            label?
            <label className={`form-label ${otherProps.required?'required':''}`}>
                <span>{label}</span>
            </label>
            :null
        }
        {
            otherProps.type==='textarea'?(
                <textarea className="form-input" onChange={handleChange} {...otherProps} />
            ):(
                <input className="form-input" onChange={handleChange} {...otherProps} />
            )
        }
    </div>
)

export default FormInput;
