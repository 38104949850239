import serverCall from './serverCall';

// User
export const signIn = (username: string, password: string, remember: Boolean) => {
    const body = {username, password, remember};
    return serverCall({
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        url: '/auth/signin/',
        data: body
    });
}

// Company
export const getCompanies = () => {
    return serverCall({method:'GET', url:`/companies`});
};

// Token
export const getToken = (tokenId: string) => {
    return serverCall({method:'GET', url:`/tokens/${tokenId}`});
}

export const updateToken = (tokenId: string, data: any) => {
    return serverCall({method:'PUT', url:`/tokens/${tokenId}`, data: data});
}

export const getTokens = (companyId: string) => {
    return serverCall({method:'GET', url:`/companies/${companyId}/tokens`});
}

// Usage
export const getUsages = (tokenId: string, query: any) => {
    return serverCall({method:'GET', url:`/usages/${tokenId}`, params: query});
}

// Metadata
export const getMetadata = (tokenId: string, query: any) => {
    return serverCall({method:'GET', url:`/metadata/${tokenId}`, params: query});
}
