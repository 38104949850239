import { takeLatest, call, put, all } from 'redux-saga/effects';

import { getMetadata } from '../../modules/apiHelper';
import UsageActionTypes from './metadata.types';
import { AnyAction } from '../../modules/commonTypes'

import {
    fetchMetadataSuccess,
    fetchMetadataFailure,
    } from './metadata.actions';

export function* fetchMetadataAsync({payload: data} : AnyAction):any {
    try {
        const {tokenId, ...query} = data;
        const response = yield getMetadata(tokenId, query);
        const { metadata } = response.data;
        yield put(fetchMetadataSuccess(data, metadata));
    } catch(error : any) {
        yield put(fetchMetadataFailure(error.data));
    }
}


export function* onFetchMetadata() {
    yield takeLatest(
        UsageActionTypes.FETCH_METADATA_START,
        fetchMetadataAsync
    );
}


export function* metadataSagas(){
    yield all([
        call(onFetchMetadata),
    ]);
}
