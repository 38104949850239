import { takeLatest, call, put, all } from 'redux-saga/effects';

import { getToken, updateToken, getTokens } from '../../modules/apiHelper';

import TokenActionTypes from './token.types';
import { AnyAction } from '../../modules/commonTypes'

import { 
    fetchTokenSuccess,
    fetchTokenFailure,
    updateTokenSuccess,
    updateTokenFailure,
    fetchTokensSuccess,
    fetchTokensFailure,
    } from './token.actions';

export function* fetchTokenAsync({ payload: tokenId }: AnyAction):any {
    try {
        const response = yield getToken(tokenId);
        const {token} = response.data;
        yield put(fetchTokenSuccess(token));
    } catch(error : any) {
        yield put(fetchTokenFailure(error.data));
    }
}

export function* updateTokenAsync({ payload: { tokenId, data} }: AnyAction):any {
    try {
        const response = yield updateToken(tokenId, data);
        const { token } = response.data;
        
        yield put(updateTokenSuccess(token));
    } catch(error : any) {
        yield put(updateTokenFailure(error.data));
    }
}

export function* fetchTokensAsync({ payload: companyId }: AnyAction):any {
    try {
        const response = yield getTokens(companyId);
        const {tokens} = response.data;
        yield put(fetchTokensSuccess(tokens));
    } catch(error : any) {
        yield put(fetchTokensFailure(error.data));
    }
}


export function* onFetchToken() {
    yield takeLatest(
        TokenActionTypes.FETCH_TOKEN_START,
        fetchTokenAsync
    );
}

export function* onUpdateToken() {
    yield takeLatest(
        TokenActionTypes.UPDATE_TOKEN_START,
        updateTokenAsync
    );
}

export function* onFetchTokens() {
    yield takeLatest(
        TokenActionTypes.FETCH_TOKENS_START,
        fetchTokensAsync
    );
}


export function* tokenSagas(){
    yield all([
        call(onFetchToken),
        call(onUpdateToken),
        call(onFetchTokens),
    ]);
}
