import ViewActionTypes from './view.types';
import { AnyAction } from '../../modules/commonTypes'

const INITIAL_STATE = {
    message_modal_status: false,
};

const viewReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch(action.type) {
        case ViewActionTypes.TOGGLE_MESSAGE_MODAL:
            if(action.payload !== undefined)
                return {
                    ...state,
                    message_modal_status: action.payload
                }
            else
                return {
                    ...state,
                    message_modal_status: !state.message_modal_status
                }	
        default:
            return state;
    }
}

export default viewReducer;
