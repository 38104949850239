import MetadataActionTypes from './metadata.types';
import { AnyAction } from '../../modules/commonTypes'

const INITIAL_STATE =  {
    query: null,
    metadata: null,
    loading: false,
    error: null,
    status: null,
};

const filterQuery = (query: any) => {
    let { tokenId, ...params} = query;
    return params
}

const metadataReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case MetadataActionTypes.FETCH_METADATA_START:
            return {
                ...state,
                query: filterQuery(action.payload),
                metadata: null,
                loading: true,
                error: null,
                status: action.type,
            };
        case MetadataActionTypes.FETCH_METADATA_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                query: filterQuery(action.payload.query),
                metadata: action.payload.metadata,
                status: action.type
            };
        case MetadataActionTypes.FETCH_METADATA_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: action.type
        };
        default:
            return state
    }
};

export default metadataReducer;
