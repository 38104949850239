import axios from "axios";
import Auth from "./authenticate";
let URL = '/'

// Interceptors handle network error
axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        // handle signin failure
        if(error?.response?.data?.detail) {
            error.response = {
                data: { status: "error", message: error.response.data.detail },
                status: 400,
            };
        }
        if(!error?.response?.data?.status) {
            error.response = {
                data: { status: "error", message: "Network Error" },
                status: 500,
            };
        }
        if (error.response.status === 401){
            // Handle sign_out in case token expired
            Auth.signOut();
            window.location.reload();
        }
        return Promise.reject(error.response);
    }
);

const serverCall = config => {
    if (Auth.getUser()) {
        const token = Auth.getToken();
        config.headers = {
            accept: "application/json",
            Authorization: `${token.token_type} ${token.access_token}`,
        };
    }
    
    config.baseURL = URL;
    config.timeout = 60 * .5 * 1000; // Let's say you want to wait at least .5 mins
    return axios(config);
};

export default serverCall;
