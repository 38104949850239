import { createSelector } from 'reselect';


const selectTokenState = (state: any) => state.token;

export const selectTokens = createSelector(
    [selectTokenState],
    token => token.tokens
);

export const selectSingleToken = createSelector(
    [selectTokenState],
    token => token.token
);

export const selectIsTokenLoading = createSelector(
    [selectTokenState],
    token => token.loading
);

export const selectIsTokenLoaded = createSelector(
    [selectTokenState],
    token => !!token.token
);

export const selectIsTokensLoaded = createSelector(
    [selectTokenState],
    token => !!token.tokens
);

export const selectTokenError = createSelector(
    [selectTokenState],
    token => token.error
);

export const selectTokenStatus = createSelector(
    [selectTokenState],
    token => token.status
);
