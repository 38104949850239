import './custom-button.styles.scss';

type Props = {
    children?: any;
    type?: any;
    inverted?: boolean;
    className?: string;
    onClick?: (e:any) => void;
};

const CustomButton = ({ children, inverted ,...otherProps } : Props) => {
    return (
    <button {...otherProps} className={`custom-button ripple ${inverted?'inverted':''}`}>
        {children}
    </button>
    )
}

export default CustomButton;
