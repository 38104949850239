import UsageActionTypes from './usage.types';
import { UsageType , UsageQueryType, ErrorType } from '../../modules/commonTypes'

// Fetch
export const fetchUsages = (query: UsageQueryType) => ({
    type: UsageActionTypes.FETCH_USAGES_START,
    payload: query
});

export const fetchUsagesSuccess = (query: any, usages: UsageType[]) => ({
    type: UsageActionTypes.FETCH_USAGES_SUCCESS,
    payload: {query, usages}
});

export const fetchUsagesFailure = (error: ErrorType) => ({
    type: UsageActionTypes.FETCH_USAGES_FAILURE,
    payload: error
});
