import './badge.styles.scss';

type Props = {
    content: any;
    onClick?: () => void;
};

const Badge = ({content, onClick} : Props) => {
    let handleClick = () => {
        if(onClick) onClick()
    }

    return (
    <div className="badge" onClick={handleClick}>
        {content}
    </div>
)}

export default Badge;
