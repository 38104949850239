import UsageActionTypes from './usage.types';
import { AnyAction } from '../../modules/commonTypes'

const INITIAL_STATE =  {
    query: null,
    usages: null,
    loading: false,
    error: null,
    status: null,
};

const filterQuery = (query: any) => {
    let { tokenId, ...params} = query;
    return params
}

const usageReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case UsageActionTypes.FETCH_USAGES_START:
            return {
                ...state,
                query: filterQuery(action.payload),
                usages: null,
                loading: true,
                error: null,
                status: action.type,
            };
        case UsageActionTypes.FETCH_USAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                query: filterQuery(action.payload.query),
                usages: action.payload.usages,
                status: action.type
            };
        case UsageActionTypes.FETCH_USAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: filterQuery(action.payload),
                status: action.type
        };
        default:
            return state
    }
};

export default usageReducer;
