import { useEffect, useState, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectCompanies } from '../../redux/company/company.selectors';
import Company from '../../components/company/company.component';
import SearchForm from '../../components/search-form/search-form.component';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';

import { CompanyType } from '../../modules/commonTypes';

import './companies-page.styles.scss';

type Props = {
    companies: CompanyType[];
};

const CompaniesPage = ({ companies } : Props) => {

    const bread_items = [{title: 'Companies', routeName: '/'}];
    
    const [filterCompanies, setFilterCompanies] = useState(companies);
    const [searchValue, setSearchValue] = useState("");
    const handleChange = (e : any) => {
        let { value } = e.target;
        setSearchValue(value);
    };

    useEffect(() => {
        const searchFn = (value : string) => {
            let _filterCompanies = companies.filter(
                item => item.name.toLowerCase().includes(value.toLowerCase())
            );
            setFilterCompanies(_filterCompanies);
        };
        searchFn(searchValue);
    }, [searchValue, companies])

    const topRef = useRef<any>(null);
    useEffect(() => {
        topRef.current.scrollIntoView({behavior: 'smooth', block: 'start' });
    }, []);

    return (
        <div className="companies-page" ref={topRef}>
            <div className="container">
                <div className="search-container">
                    <SearchForm placeHolder={'Company Name'} onChange={handleChange}/>
                </div>
                <Breadcrumb items={bread_items}/>
                <div className="companies-items">
                    {
                        filterCompanies.map(item => <Company key={item.id} item={item} />)
                    }
                </div>
                {
                    !filterCompanies.length &&
                    <div className="empty-message">
                        No Companies Found
                    </div> 
                }
            </div>
        </div>
)};

const mapStateToProps = createStructuredSelector({
    companies: selectCompanies
})

export default connect(mapStateToProps)(CompaniesPage);
