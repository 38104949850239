import { useRef, useEffect, useState, createRef, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectSingleToken } from '../../redux/token/token.selectors';
import { selectUsageQuery, selectUsages } from '../../redux/usage/usage.selectors';

import { TokenType, UsageType, UsageQueryType, MachineUsageType, ModelUsageType } from '../../modules/commonTypes';

import TokenOverview from '../../components/token-overview/token-overview.component';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import Usage from '../../components/usage/usage.component';
import { Collapsible, CollapsibleIcon } from '../../components/collapsible/collapsible.component';



import './usages-page.styles.scss';
// import FilterPanel from '../../components/filter-panel/filter-panel.component';

type Props = {
    token: TokenType;
    usages: UsageType[];
    query: UsageQueryType;
};

const UsagesPage = ({ token, usages, query } : Props) => {

    const bread_items = [
        {title: 'Companies', routeName: '/dashboard'},
        {title: token.company_name, routeName: `/dashboard/companies/${token.company_name}`},
        {title: 'Usages'}
    ];

    const topRef = useRef<any>(null);
    useEffect(() => {
        topRef.current.scrollIntoView({behavior: 'smooth', block: 'start' });
    }, []);
    let collapseRef = createRef();
    const [filterQuery, setFilterQuery ] = useState({});

    let totalUsages = useMemo(()=> {
        // concat all mac address usages
        let usages_dict: {[id: string] : ModelUsageType[]; } = usages.reduce((acc : any, { usages }) => {
            usages.forEach(({timestamp, models}) => {
                acc[timestamp] = acc[timestamp] ? [...acc[timestamp], ...models] : models
            });
            return acc;
        }, {})
        let all_usages: MachineUsageType[] = Object.entries(usages_dict).map(item  => ({timestamp: item[0], models: item[1]}));
        return {mac_address: String(query.mode), usages: all_usages};
    }, [usages, query.mode]);

    return (
        <div className="usages-page" ref={topRef}>
            <div className="container">
                <Breadcrumb items={bread_items} />
                <TokenOverview token_info={token} devices_count={usages.length} />
                {/* <FilterPanel usages={usages} onChange={setFilterQuery}/> */}

                <Usage key={'total_usage'} title={'Total Usage:'} query={filterQuery} item={totalUsages} mode={query.mode}/>

                <div className='mac-info'>
                    <CollapsibleIcon collapseRef={collapseRef} isOpen={false} />
                </div>
                <Collapsible ref={collapseRef}>
                    {
                        usages.map(item =>
                            <Usage key={item.mac_address} query={filterQuery} item={item} mode={query.mode}/>
                        )
                    }

                </Collapsible>
            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    token: selectSingleToken,
    query: selectUsageQuery,
    usages: selectUsages
})


export default connect(mapStateToProps)(UsagesPage);
