import { combineReducers } from 'redux';

import viewReducer     from './view/view.reducer';
import userReducer     from './user/user.reducer';
import companyReducer  from './company/company.reducer';
import tokenReducer    from './token/token.reducer';
import usageReducer    from './usage/usage.reducer';
import metadataReducer from './metadata/metadata.reducer';

const rootReducer = combineReducers({
    view:     viewReducer,
    user:     userReducer,
    company:  companyReducer,
    token:    tokenReducer,
    usage:    usageReducer,
    metadata: metadataReducer,
});

export default rootReducer;
