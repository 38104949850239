import React from 'react';

import { FaCaretUp, FaCaretDown} from 'react-icons/fa';
import './collapsible.styles.scss';

type Props = {
    collapseRef: any;
    isOpen: any;
    children?: any
};

export const CollapsibleIcon = ({collapseRef, isOpen, children} : Props) => {
    const [ toggle, setToggle] = React.useState(isOpen);
    const handleToggle = () => {
        setToggle((state: boolean) => !state)
        if(collapseRef) collapseRef.current.className = `collapsible ${!toggle?'open':null}`;
    }
    return (
    <div className="collapsible-icon" onClick={handleToggle}>
        <span>
            {children}
            { toggle ? 'Show Less' : 'Show More' }
        </span>
        <span>
            <div className="icon">{toggle? <FaCaretUp />: <FaCaretDown />}</div>
        </span>
    </div>
)};


export const Collapsible = React.forwardRef(({children} : {children: any}, ref: any) => {
    return(
    <div ref={ref} className="collapsible">
        {children}
    </div>
)});
