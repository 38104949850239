import { useEffect, useState, useRef } from 'react';
import { createStructuredSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectTokens } from '../../redux/token/token.selectors';
import { TokenType } from '../../modules/commonTypes';
import SearchForm from '../../components/search-form/search-form.component';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import Token from '../../components/token/token.component';
import './tokens-page.styles.scss';

type Props = {
    tokens: TokenType[];
    match: any;
};

const TokensPage = ({ tokens, match }: Props) => {
    let companyId = match.params.companyId;
    const bread_items = [
        {title: 'Companies', routeName: '/dashboard'},
        {title: companyId, routeName: `/dashboard/companies/${companyId}`}
    ];

    const [filterTokens, setFilterTokens] = useState(tokens);
    const [searchValue, setSearchValue] = useState("");
    const handleChange = (e: any) => {
        let { value } = e.target;
        setSearchValue(value);
    };

    useEffect(() => {
        const searchFn = (value: string) => {
            let _filterTokens = tokens.filter(
                item =>
                item.name.toLowerCase().includes(value.toLowerCase()) ||
                item.token.toLowerCase().includes(value.toLowerCase())
            );
            setFilterTokens(_filterTokens);
        };
        searchFn(searchValue);
    }, [searchValue, tokens])


    const topRef = useRef<any>(null);
    useEffect(() => {
        topRef.current.scrollIntoView({behavior: 'smooth', block: 'start' });
    }, []);

    return (
        <div className="tokens-page" ref={topRef}>
            <div className="container">
                <div className="search-container">
                    <SearchForm placeHolder={'Token Name or Key'} onChange={handleChange}/>
                </div>
                <Breadcrumb items={bread_items} />
                <div className="tokens-items">
                    {
                        filterTokens.map(item => <Token key={item.id} item={item} />)
                    }
                </div>
                {
                    !filterTokens.length &&
                    <div className="empty-message">
                        No Tokens Found
                    </div>
                }
            </div>
        </div>
)};

const mapStateToProps = createStructuredSelector({
    tokens: selectTokens
})

export default withRouter(connect(mapStateToProps)(TokensPage));
