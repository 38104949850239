import React, { useEffect } from 'react';
import { Dispatch } from 'redux';
import { registerNav } from './modules/navigation'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentUser } from './redux/user/user.selectors';
import { checkUserSession } from './redux/user/user.actions';


import HeaderTop   from './components/header-top/header-top.component';
import Footer      from './components/footer/footer.component';

import SignPage      from './pages/sign-page/sign-page.component';
import CompaniesPage from './pages/companies-page/companies-page.container';
import TokensPage    from './pages/tokens-page/tokens-page.container';
import UsagesPage    from './pages/usages-page/usages-page.container';
import MetadataPage  from './pages/metadata-page/metadata-page.container';


import './App.scss';


type Props = {
    checkUserSession:() => void;
    currentUser: any;
};

const App = ({ checkUserSession, currentUser}: Props) => {
    useEffect(()=> {
        checkUserSession();
    }, [checkUserSession]);

    return (
        <div className="App">
            <Router ref={registerNav}>
                <HeaderTop />

                <div className="page-container">
                    {
                        currentUser ?
                        <Switch>
                            <Route exact path='/dashboard' component={CompaniesPage} />
                            <Route exact path='/dashboard/companies/:companyId' component={TokensPage} />
                            <Route exact path='/dashboard/tokens/:tokenId/usages' component={UsagesPage} />
                            {
                                Boolean(currentUser.role === "admin") &&
                                <Route exact path='/dashboard/tokens/:tokenId/metadata' component={MetadataPage} />
                            }
                            <Redirect to='/dashboard'/>
                        </Switch>
                        :
                        <SignPage />
                    }
                </div>
                <Footer />
            </Router>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    currentUser: selectCurrentUser,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    checkUserSession: () => dispatch(checkUserSession()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
