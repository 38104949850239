import FormInput from '../form-input/form-input.component';

import './search-form.styles.scss';

const SearchForm = ({ placeHolder, onChange }: { placeHolder: string, onChange: any}) => {

    const handleChange = (e: Event) => {
        if (onChange) onChange(e);
    }

    return (
        <div className="search-container">
        <FormInput
            type='search'
            name='search'
            placeholder={placeHolder}
            handleChange={handleChange}
            required/>
        </div>
    )
}

export default SearchForm;
