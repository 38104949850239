
import './form-checkbox.styles.scss';

type Props = {
    id: any;
    name: string;
    label: string;
    className?: string;
    type: any;
    defaultChecked: boolean;
    handleChange?: ( e :any) => void;
}

const FormCheckBox = ({handleChange, label, id, ...otherProps}: Props) => (
    <div className="label-check">
        <div className="checkbox">
            <input onChange={handleChange}  {...otherProps} id={id}/>
            <label htmlFor={id}>
                <span>{label}</span>
            </label>
        </div>
    </div>
)

export default FormCheckBox;
