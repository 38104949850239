export const textSlicer = (text, length) => {
	return text.slice(0, length) + (text.length > length ? ' ...': '');
}

export const slugify = str => {
	return str.replace(/\s/g, '-');
}

export const nameFormatter = str => {
	return str.replace(/_/g, ' ');
}

export const deepCopy = obj => {
	return JSON.parse(JSON.stringify(obj));
}
