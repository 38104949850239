import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';

import logger from 'redux-logger';
import rootSaga from './root-saga'
import rootReducer from './root-reducer';

const sagaMiddleware = createSagaMiddleware();
const middle_wares = [sagaMiddleware];

if(process.env.NODE_ENV === 'development'){
    middle_wares.push(logger); 
}

export const store = createStore(rootReducer, applyMiddleware(...middle_wares));
sagaMiddleware.run(rootSaga);


export default store;
