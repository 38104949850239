import { useRef, useEffect, useMemo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { selectSingleToken } from '../../redux/token/token.selectors';
import { selectMetadataQuery, selectMetadata } from '../../redux/metadata/metadata.selectors';

import { TokenType, MetadataType } from '../../modules/commonTypes';
import TokenOverview from '../../components/token-overview/token-overview.component';
import Metadata from '../../components/metadata/metadata.component';
import Breadcrumb from '../../components/breadcrumb/breadcrumb.component';
import './metadata-page.styles.scss';

type Props = {
    token: TokenType;
    metadata: MetadataType[];
};

const MetadataPage = ({ token, metadata } : Props) => {

    const bread_items = [
        {title: 'Companies', routeName: '/dashboard'},
        {title: token.company_name, routeName: `/dashboard/companies/${token.company_name}`},
        {title: 'Metadata'}
    ];

    const topRef = useRef<any>(null);
    useEffect(() => {
        topRef.current.scrollIntoView({behavior: 'smooth', block: 'start' });
    }, []);

    let data = useMemo(() => {
        let metadata_dict = metadata.reduce((acc : any, item: MetadataType) => {
            if (acc[item.mac_address]) {
                acc[item.mac_address].push(item)
            }
            else {
                acc[item.mac_address] = [item]
            }
            return acc
        }, {});

        // convert to list
        let metadata_list = []
        for (const [mac_address, list] of Object.entries(metadata_dict)) {
            metadata_list.push({
                mac_address, metadata: list
            })
        }

        return metadata_list;
    }, [metadata]);

    return (
        <div className="usages-page" ref={topRef}>
            <div className="container">
                <Breadcrumb items={bread_items} />
                <TokenOverview token_info={token} devices_count={data.length} />
                {
                    data.map((item: any) =>
                        <Metadata key={item.mac_address} metadata={item}/>
                    )
                }
            </div>
        </div>
    )
};

const mapStateToProps = createStructuredSelector({
    token: selectSingleToken,
    query: selectMetadataQuery,
    metadata: selectMetadata
})


export default connect(mapStateToProps)(MetadataPage);
