import { useMemo } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import './date-range-picker.styles.scss';
import "react-datepicker/dist/react-datepicker.css";

type Props = {
    start_date?: number | null;
    end_date?: number | null; 
    onChange?:(range: any) => void;
};

const DateRangePicker = ({ start_date, end_date, onChange }: Props) => {

    let date_range = useMemo<any>(() => {
        return { 
            start_date: start_date ? (start_date * 1000): null,
            end_date: end_date ? (end_date * 1000): null
        }
    }, [start_date, end_date])

    const handleChange = (name : string, date: any) => {
        let unix_date = date ? moment(date).unix() : null;

        if (onChange) onChange({
            start_date, end_date, [name]: unix_date
        });

    }
    
    return (
    <div className='date-range-picker'>
        <div className='row'>
            <span className='label'>From</span>
            <span>
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={date_range.start_date}
                    onChange={date => handleChange('start_date', date)}
                    showIcon
                    isClearable
                    placeholderText="YYYY-MM-DD"
                    closeOnScroll={true}
                />
            </span>
        </div>
        <div className='row'>
            <span className='label'>To</span>
            <span>
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={date_range.end_date}
                    onChange={date => handleChange('end_date', date)}
                    showIcon
                    isClearable
                    placeholderText="YYYY-MM-DD"
                    closeOnScroll={true}
                />
            </span>
        </div>
    </div>
)}

export default DateRangePicker;
