import React, { useEffect, useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUserError } from '../../redux/user/user.selectors';
import { ErrorType } from '../../modules/commonTypes';

import FormInput from '../form-input/form-input.component';
import CustomButton from '../custom-button/custom-button.component';
import FormCheckBox from '../form-checkbox/form-checkbox.component';

import { signIn } from '../../redux/user/user.actions';

import './sign-in.styles.scss';

type Props = {
    error: ErrorType,
    signIn: (username: string, password: string, remember: boolean) => void;
}
const SignIn = ({ error, signIn } : Props) => {
    const boxRef = React.useRef<any>(null);
    useEffect(() => {
        boxRef.current.scrollIntoView({behavior: 'smooth', block: 'start'});
    })
    
    const [userCredentials, setCredentials] = useState({username: '', password: '', remember: false});
    const {username, password, remember} = userCredentials;
    const handleSubmit = (event : any) => {
        event.preventDefault();
        signIn(username, password, remember);
    }

    const handleChange = (event: any) => {
        let { name, value, type, checked} = event.target;
        if(type === 'checkbox')
            setCredentials({ ...userCredentials, [name]: checked});
        else
            setCredentials({ ...userCredentials, [name]: value});
    }

    return (
        <div className='sign-in' id="sign-in" ref={boxRef}>
            <div className="header">
                <h2 className='title'>Token Manager</h2>
            </div>
            <div className="body">
                
                <form onSubmit={handleSubmit}>
                
                    <FormInput 
                        type='text'
                        name='username'
                        value={username}
                        label='Username'
                        handleChange={handleChange}
                        required/>

                    
                    <FormInput
                        label='Password'
                        name='password'
                        type='password'
                        value={password}
                        handleChange={handleChange}
                        required />
                    <FormCheckBox
                        id="remember"
                        label = 'Remember me'
                        type='checkbox'
                        name='remember'
                        defaultChecked={false}
                        handleChange={handleChange}/>
                    {
                        Boolean(error?.message) &&
                        <div className='error-message'>
                            {error?.message}
                        </div>
                    }
                    <CustomButton type='submit'>
                        Sign In
                    </CustomButton>
                </form>
            </div>
        </div>
    );
};

const mapStateToProps = createStructuredSelector({
    error: selectUserError
})

const mapDispatchToProps = (dispatch : Dispatch) =>({
    signIn: (username: string, password: string, remember: boolean) => dispatch(signIn({username, password, remember}))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
