import { createSelector } from 'reselect';


const selectUsageState = (state: any) => state.usage;

export const selectUsages = createSelector(
    [selectUsageState],
    usage => usage.usages
);

export const selectIsUsageLoading = createSelector(
    [selectUsageState],
    usage => usage.loading
);

export const selectIsUsagesLoaded = createSelector(
    [selectUsageState],
    usage => !!usage.usages
);

export const selectUsageQuery = createSelector(
    [selectUsageState],
    usage => usage.query
);

export const selectUsageError = createSelector(
    [selectUsageState],
    usage => usage.error
);

export const selectUsageStatus = createSelector(
    [selectUsageState],
    usage => usage.status
);
