import TokenActionTypes from './token.types';
import { TokenType, UpdateTokenType, ErrorType } from '../../modules/commonTypes'

// Fetch Single
export const fetchToken = (tokenId: string) => ({
    type: TokenActionTypes.FETCH_TOKEN_START,
    payload: tokenId
});

export const fetchTokenSuccess = (Token: TokenType) => ({
    type: TokenActionTypes.FETCH_TOKEN_SUCCESS,
    payload: Token
});

export const fetchTokenFailure = (error: ErrorType) => ({
    type: TokenActionTypes.FETCH_TOKEN_FAILURE,
    payload: error
});

// Edit Single
export const updateToken = (tokenId: string, data: UpdateTokenType) => ({
    type: TokenActionTypes.UPDATE_TOKEN_START,
    payload: {tokenId, data}
});

export const updateTokenSuccess = (Token: TokenType) => ({
    type: TokenActionTypes.UPDATE_TOKEN_SUCCESS,
    payload: Token
});

export const updateTokenFailure = (error: ErrorType) => ({
    type: TokenActionTypes.UPDATE_TOKEN_FAILURE,
    payload: error
});

// Fetch All
export const fetchTokens = (companyId: string) => ({
    type: TokenActionTypes.FETCH_TOKENS_START,
    payload: companyId
});

export const fetchTokensSuccess = (Tokens: TokenType[]) => ({
    type: TokenActionTypes.FETCH_TOKENS_SUCCESS,
    payload: Tokens
});

export const fetchTokensFailure = (error: ErrorType) => ({
    type: TokenActionTypes.FETCH_TOKENS_FAILURE,
    payload: error
});
