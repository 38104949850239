import TokenActionTypes from './token.types';
import { AnyAction } from '../../modules/commonTypes'

const INITIAL_STATE =  {
    token: null,
    tokens: null,
    loading: false,
    Error: null,
    status: null,
};

const tokenReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case TokenActionTypes.FETCH_TOKEN_START:
        case TokenActionTypes.FETCH_TOKENS_START:
            return {
                ...state,
                token: null,
                tokens: null,
                loading: true,
                error: null,
                status: action.type,
            };
        case TokenActionTypes.UPDATE_TOKEN_START:
            return {
                ...state,
                loading: true,
                error: null,
                status: action.type,
            };
        case TokenActionTypes.FETCH_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                token: action.payload,
                status: action.type
            };
        case TokenActionTypes.UPDATE_TOKEN_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                token: action.payload,
                status: action.type
            };
        case TokenActionTypes.FETCH_TOKENS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                tokens: action.payload,
                status: action.type
            };
        case TokenActionTypes.FETCH_TOKEN_FAILURE:
        case TokenActionTypes.UPDATE_TOKEN_FAILURE:
        case TokenActionTypes.FETCH_TOKENS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: action.type
        };
        default:
            return state
    }
};

export default tokenReducer;
