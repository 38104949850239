import { FaBuilding } from 'react-icons/fa';
import { CompanyType } from '../../modules/commonTypes';
import CustomButton from '../custom-button/custom-button.component';
import { Link } from 'react-router-dom';
import './company.styles.scss';

type Props = {
    item: CompanyType;
};

const Company = ({ item } : Props) => {
    const { name } = item;
    return (
        <div className='company-item'>
            <div className='image'>
                <div className='icon'>
                    <FaBuilding size={80}/>
                </div>
            </div>

            <div className="name">
                { name }
            </div>
            <Link className="view-link" to={`/dashboard/companies/${name}`}>
                <CustomButton className="custom-button" inverted>
                    View
                </CustomButton>
            </Link>
        </div>
    )
}

export default Company;
