import CompanyActionTypes from './company.types';
import { AnyAction } from '../../modules/commonTypes'

const INITIAL_STATE =  {
    companies: null,
    loading: false,
    error: null,
    status: null,
};

const companyReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch(action.type){
        case CompanyActionTypes.FETCH_COMPANIES_START:
            return {
                ...state,
                companies: null,
                loading: true,
                error: null,
                status: action.type,
            };
        case CompanyActionTypes.FETCH_COMPANIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                companies: action.payload,
                status: action.type
            };
        case CompanyActionTypes.FETCH_COMPANIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                status: action.type
        };
        default:
            return state
    }
};

export default companyReducer;
