import React, { useEffect } from 'react';
import { setQuery } from '../../modules/navigation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectSingleToken } from '../../redux/token/token.selectors';
import { selectUsageQuery } from '../../redux/usage/usage.selectors';
import { UsageQueryType } from '../../modules/commonTypes';

import DateRangePicker from '../date-range-picker/date-range-picker.component';
import './usages-menu.styles.scss';

type Props = {
    query: UsageQueryType,
}

const UsagesMenu = ({ query }: Props) => {
    let lastScrollY = 0;
    let ticking = false;
    let selected_mode = query?.mode;
    
    const modes: string[] = ['monthly', 'weekly', 'daily'] 
    const navMenu = React.useRef<any>(null);
    let handleScroll = () => {
        lastScrollY = window.scrollY;
        if (!ticking) {
            navMenu.current && window.requestAnimationFrame(() => {
                navMenu.current.style.height = lastScrollY > 15 ? `0`: `3rem`;
                navMenu.current.style.opacity = lastScrollY > 15 ? `0`: `1`;
                navMenu.current.style.pointerEvents = lastScrollY > 15 ? `none`: `auto`;

                ticking = false;
            });
            ticking = true;
        }
    };

    useEffect(()=> {
        window.addEventListener('scroll', handleScroll, true);
    });

    const handleClick = (mode: string) => {
        setQuery({...query, mode})
    }

    const handleDateRange = (data : any) => {
        let {start_date, end_date } = data;
        setQuery({...query, start_date, end_date});
    }
    
    return (
    <div className="usages-menu" >
        <div className="navbar" ref={navMenu}>
            <div className='mode-select'>
                {
                    modes.map(name => (
                        <div 
                            key={name}
                            className={`nav-item ${selected_mode===name?'selected':''}`}
                            onClick={() => handleClick(name)}>
                            {name}
                        </div>
                        )
                    )
                }

            </div>
            <div className='date-select'>
                <DateRangePicker start_date={query?.start_date} end_date={query?.end_date} onChange={handleDateRange}/>
            </div>
        </div>
    </div>);
}	

const mapStateToProps = createStructuredSelector({
    token: selectSingleToken,
    query: selectUsageQuery,
})


export default connect(mapStateToProps)(UsagesMenu);
