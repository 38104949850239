import { useEffect} from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ErrorType } from '../../modules/commonTypes';

import { createStructuredSelector } from 'reselect';
import { selectIsTokensLoaded, selectIsTokenLoading, selectTokenError } from '../../redux/token/token.selectors';
import { fetchTokens } from '../../redux/token/token.actions';

import TokensPage from './tokens-page.component';
import LoaderPage from '../loader-page/loader-page.component';


import './tokens-page.styles.scss';

type Props = {
    isLoading: boolean;
    isLoaded: boolean;
    error: ErrorType;
    fetchTokens: (companyId : string) => void;
    match: any;
};

const TokensPageContainer = ({ isLoaded, isLoading, error, fetchTokens, match } : Props) => {
    let companyId = match.params.companyId;

    useEffect(()=> {
        fetchTokens(companyId);
    }, [fetchTokens, companyId]);

    return (isLoaded && !error) ?
        <TokensPage />
        :
        <LoaderPage isLoading={isLoading} error={error} retryFuncs={[() => fetchTokens(companyId)]}/>
};

const mapStateToProps = createStructuredSelector({
    isLoading: selectIsTokenLoading,
    isLoaded: selectIsTokensLoaded,
    error: selectTokenError
})

const mapDispatchToProps = ( dispatch : Dispatch) => ({
    fetchTokens: (companyId: string) => dispatch(fetchTokens(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TokensPageContainer);
