import { useEffect } from 'react';
import queryString from 'query-string';

import { Dispatch } from 'redux';
import { MetadataQueryType, ErrorType } from '../../modules/commonTypes';
import { setQuery } from '../../modules/navigation';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { fetchToken } from '../../redux/token/token.actions';
import { fetchUsages } from '../../redux/usage/usage.actions';
import { selectIsTokenLoaded, selectIsTokenLoading, selectTokenError } from '../../redux/token/token.selectors';
import { selectIsUsagesLoaded, selectIsUsageLoading, selectUsageError } from '../../redux/usage/usage.selectors';
import LoaderPage from '../loader-page/loader-page.component';
import UsagesMenu  from '../../components/usages-menu/usages-menu.component';
import UsagesPage from './usages-page.component';

type Props = {
    isTokenLoading: boolean;
    isTokenLoaded: boolean;
    tokenError: ErrorType;
    isUsagesLoading: boolean;
    isUsagesLoaded: boolean;
    usageError: ErrorType;
    fetchToken: (tokenId: string) => void;
    fetchUsages: (query: MetadataQueryType) => void;
    match: any;
    location: any;
};

const UsagesPageContainer = ({ isTokenLoading, isTokenLoaded, tokenError, isUsagesLoading, isUsagesLoaded, usageError, fetchToken, fetchUsages, match, location } : Props) => {
    let tokenId = match.params.tokenId;

    useEffect(()=> {
        fetchToken(tokenId);
    }, [fetchToken, tokenId]);

    useEffect(()=> {
        let query = queryString.parse(location.search);
        setQuery(query)
        fetchUsages({tokenId, ...query});
    }, [fetchUsages, tokenId, location.search]);

    const retryFuncs = [
        () => fetchToken(tokenId),
        () => fetchUsages({tokenId, ...queryString.parse(location.search)})
    ]
    return (
        <>
            <UsagesMenu />
            {
                (isUsagesLoaded && isTokenLoaded && !tokenError && !usageError) ? 
                <UsagesPage />
                :
                <LoaderPage 
                    isLoading={isTokenLoading || isUsagesLoading}
                    error={ tokenError || usageError}
                    retryFuncs={retryFuncs}/>
            }
        </>
    )
};

const mapStateToProps = createStructuredSelector({
    isTokenLoading: selectIsTokenLoading,
    isTokenLoaded: selectIsTokenLoaded,
    tokenError: selectTokenError,
    isUsagesLoading: selectIsUsageLoading,
    isUsagesLoaded: selectIsUsagesLoaded,
    usageError: selectUsageError
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    fetchToken: (tokenId: string) => dispatch(fetchToken(tokenId)),
    fetchUsages: (query: MetadataQueryType) => dispatch(fetchUsages(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsagesPageContainer);
