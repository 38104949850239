import { createSelector } from 'reselect';


const selectCompanyState = (state: any) => state.company;

export const selectCompanies = createSelector(
    [selectCompanyState],
    company => company.companies
);

export const selectCompanyError = createSelector(
    [selectCompanyState],
    company => company.error
);

export const selectIsCompaniesLoading = createSelector(
    [selectCompanyState],
    company => company.loading
);

export const selectIsCompaniesLoaded = createSelector(
    [selectCompanyState],
    company => !!company.companies
);

export const selectCompanyStatus = createSelector(
    [selectCompanyState],
    company => company.status
);
