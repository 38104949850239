
import queryString from 'query-string';

let history: any;

export const registerNav = (ref: any) => {
    history = ref.history;
}

const jumpTo = (uri: string) => {
    history.push(uri)
}

export const goBack = () => {
    history.goBack();
}

export const goTo = (uri: string)=>{
    window.location.href = uri;
}

export const setQuery = (query: any) => {
    // remove null values
    query = Object.fromEntries(Object.entries(query).filter(([_, v]) => v != null));
    let strQuery = queryString.stringify(query);
    let baseUrl = history.location.pathname;
    history.push(`${baseUrl}?${strQuery}`)
}

export default jumpTo;
