import { UserType } from './commonTypes';
class Auth {
    user: any;
    constructor() {
        try {
            this.user = JSON.parse(localStorage.getItem("auth") || "");
        } catch {
            this.user = null;
        }
    }
    setUser(user: UserType) {
        this.user = user;
        localStorage.setItem("auth", JSON.stringify(user));
    }
    getToken() {
        return {
            access_token: this.user.access_token || null, token_type: this.user.token_type || null
        };
    }

    getUser() {
        return this.user;
    }
    signOut() {
        this.user = null;
        localStorage.removeItem("auth");
    }
}

const auth = new Auth()
export default auth;
